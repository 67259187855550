<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol sm="12" md="6">
          <CCard border-color="info">
            <CCardHeader><CIcon name="cil-check" /> Email Sent! </CCardHeader>
            <CCardBody
              >An email has been sent to your account with instructions to reset
              your password.</CCardBody
            >
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {};
</script>

<style>
.center {
  margin: auto;
  width: 60%;

  padding: 10px;
}
</style>
